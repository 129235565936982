import React, { useState } from "react"

const InstallForm = (props) => {
    const [shopifyStoreErrorMessage, setShopifyStoreErrorMessage] = useState('');
    const sendToAppStore = () => {
        if (typeof document !== `undefined`) {
            const merchantStore = document.getElementById('merchantStore').value;
            const re = /\w+\.myshopify.com/;
            const isValidShopifyStore = re.test(String(merchantStore).toLowerCase());
            if (!isValidShopifyStore) {
              setShopifyStoreErrorMessage('Please enter a valid Shopify store');
              return;
            }
            let redirectUri = '';
            window.analytics.track('Install form completed', {
              merchant: merchantStore,
              source: window.location.href
            });
            if (props.redirectUri) {
              redirectUri = `&redirectUri=${props.redirectUri}`;
              window.location.href = `${process.env.GATSBY_MERCHANT_URI}/api/generateAuthLink?shop=${merchantStore}${redirectUri}`;
              return;
            }
            window.open(
              `${process.env.GATSBY_MERCHANT_URI}/api/generateAuthLink?shop=${merchantStore}`,
              '_blank'
            );
        }
    }

    return(
      <div>
        <div className={`sm:flex ${props.size === "medium" ? 'w-2/3': ''} ${props.align === "center" ? 'mx-auto' : ''}`}>
          <input id="merchantStore" aria-label="store"
                 className={`block w-full px-3 py-3 text-base leading-6 placeholder-gray-500 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm appearance-none sm:w-80 focus:outline-none focus:placeholder-gray-400 focus:shadow-outline-blue focus:border-blue-300 sm:flex-1`}
                 placeholder="yourstore.myshopify.com"/>
          <button type="button" onClick={sendToAppStore}
                  className="w-full px-5 py-3 mt-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-gray-800 border border-transparent rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:border-gray-900 focus:shadow-outline-gray active:bg-gray-900 sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto">
            Install Batch
          </button>
        </div>
        <div className="mt-2 text-center text-peach-500">{shopifyStoreErrorMessage}</div>
      </div>
    )
}

export default InstallForm;