import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from 'gatsby'
import InstallForm from '../components/installForm'
import Layout from "../components/layout"

const InstallPage = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          pages {
            install {
              title
              description
              url
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <Helmet>
        {/* Meta */}
          <html lang="en" />
          <title>{data.site.siteMetadata.pages.install.title}</title>
          <meta charSet="utf-8" />
          <meta name="title" content={data.site.siteMetadata.pages.install.title}/>
          <meta name="description" content={data.site.siteMetadata.pages.install.description} /> 
          <link rel="canonical" href={data.site.siteMetadata.pages.install.url} />
      </Helmet>
      <Layout>
            <div className="w-1/2 mx-auto mt-8 bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <h1 className="text-lg font-medium leading-6">
                  <span className="text-peach-500 md:bg-clip-text md:text-transparent md:bg-gradient-to-r md:to-peach-500 md:from-indigo-700">
                    Install Batch now
                  </span>
                </h1>
                <div className="max-w-xl mt-2 text-sm text-gray-500">
                  <p>
                  Enter your Shopify store's URL to install Batch directly.
                  </p>
                </div>
                
                <div className="mt-4">
                  <InstallForm />
                </div>
              </div>       
            </div>
      </Layout>
    </>
  )
}

export default InstallPage
